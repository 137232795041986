/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ItineratorError, ItineratorGetRegionsParams, ItineratorRegion, ItineratorRegionList } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Regions<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Region
   * @name GetRegions
   * @summary List all regions
   * @request GET:/regions
   * @secure
   */
  getRegions = (query?: ItineratorGetRegionsParams, params: RequestParams = {}) =>
    this.request<ItineratorRegionList, ItineratorError>({
      path: `/regions`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Region
   * @name GetRegionBySlug
   * @summary Find a region by slug
   * @request GET:/regions/{slug}
   * @secure
   */
  getRegionBySlug = (slug: string, params: RequestParams = {}) =>
    this.request<ItineratorRegion, ItineratorError>({
      path: `/regions/${slug}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
