/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ItineratorError,
  ItineratorEvent,
  ItineratorEventForm,
  ItineratorEventList,
  ItineratorGetEventsParams,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Events<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Event
   * @name GetEvents
   * @summary List all events
   * @request GET:/events
   * @secure
   */
  getEvents = (query?: ItineratorGetEventsParams, params: RequestParams = {}) =>
    this.request<ItineratorEventList, ItineratorError>({
      path: `/events`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Event
   * @name PostEvents
   * @summary Create a events
   * @request POST:/events
   * @secure
   */
  postEvents = (data: ItineratorEventForm, params: RequestParams = {}) =>
    this.request<ItineratorEvent, ItineratorError>({
      path: `/events`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Event
   * @name GetEventBySlug
   * @summary Find a event by slug
   * @request GET:/events/{slug}
   * @secure
   */
  getEventBySlug = (slug: string, params: RequestParams = {}) =>
    this.request<ItineratorEvent, ItineratorError>({
      path: `/events/${slug}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
