import { useMutation, useQuery } from "@tanstack/react-query";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
  Auth,
  signOut as firebaseSignOut,
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  User,
} from "firebase/auth";
import lodash from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ItineratorPlatform,
  loggedInApi,
  loginApi,
  logoutApi,
  useCurrentPlatform,
} from "./index";

const provider = new GoogleAuthProvider();

const defaultFirebaseConfig: ItineratorPlatform["auth_config"] = {
  apiKey: "AIzaSyCjZWCe7FNpAegj204pAMh9xVaMaegRr-g",
  authDomain: "itinerator-app.firebaseapp.com",
  projectId: "itinerator-app",
  storageBucket: "itinerator-app.firebasestorage.app",
  messagingSenderId: "107846325976",
  appId: "1:107846325976:web:e0df9858e1eb3eccbf69ad",
};

export const useLogIn = () => {
  return useMutation({
    mutationFn: (token: string) => loginApi.loginCreate({ user: { token } }),
  });
};

export const useLogout = () => {
  return useMutation({
    mutationFn: () => logoutApi.logoutDelete(),
  });
};

export const useLoginStatus = () => {
  return useQuery({
    queryFn: () => loggedInApi.loggedInList(),
    queryKey: ["loggedIn"],
  });
};

export const useAuth = () => {
  const { data: platform, isLoading } = useCurrentPlatform();
  const appRef = useRef<FirebaseApp | null>(null);
  const [auth, setAuth] = useState<Auth | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const { mutateAsync: loginToApi } = useLogIn();
  const { mutateAsync: logoutOfApi } = useLogout();

  const loginWithGoogle = useCallback(async () => {
    if (!auth) return;
    const userCredential = await signInWithPopup(auth, provider);
    const token = await userCredential.user.getIdToken();
    await loginToApi(token);
    return userCredential.user;
  }, [auth, loginToApi]);

  const loginWithEmail = useCallback(
    async (email: string, password: string) => {
      if (!auth) return;
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredential.user.getIdToken();
      await loginToApi(token);
      return userCredential.user;
    },
    [auth, loginToApi]
  );

  const signOut = useCallback(async () => {
    if (!auth) return;
    try {
      await firebaseSignOut(auth);
      await logoutOfApi();
    } finally {
      localStorage.removeItem("auth");
      localStorage.removeItem("userProfile");
    }
  }, [auth, logoutOfApi]);

  useEffect(() => {
    if (!isLoading) {
      const firebaseConfig = lodash
        .chain(platform)
        .defaultsDeep({ auth_config: defaultFirebaseConfig })
        .get("auth_config")
        .value();

      if (!appRef.current) {
        appRef.current = initializeApp(firebaseConfig);
        setAuth(getAuth(appRef.current));
      }
    }
  }, [platform, isLoading]);

  useEffect(() => {
    const unsubscribe = auth?.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, [auth]);

  return useMemo(
    () => ({
      auth,
      user,
      loading,
      loginWithGoogle,
      loginWithEmail,
      signOut,
    }),
    [auth, user, loading, loginWithGoogle, loginWithEmail, signOut]
  );
};

export const AuthContext = createContext<ReturnType<typeof useAuth> | null>(
  null
);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
