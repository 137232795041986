import {
  DehydratedState,
  QueryClient,
  UseQueryResult,
  dehydrate,
  useQuery,
} from "@tanstack/react-query";
import { ItineratorSimplePlatform } from ".";
import { RequestParams, newsletterApi, platformApi } from "./index";

export const useCurrentPlatform = (): UseQueryResult<
  ItineratorSimplePlatform,
  Error
> => {
  return useQuery({
    queryFn: () => platformApi.getPlatformBySlug("current"),
    queryKey: ["platforms", "current"],
  });
};

export const prefetchCurrentPlatform = async (
  params: RequestParams
): Promise<DehydratedState> => {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery({
    queryKey: ["platforms", "current"],
    queryFn: () => platformApi.getPlatformBySlug("current", params),
  });
  return dehydrate(queryClient);
};

export const useNewsletter = (id?: string) => {
  return useQuery({
    queryFn: () => newsletterApi.getNewsletterBySlug(id!),
    queryKey: ["newsletters", id],
    enabled: !!id,
  });
};
