import { useBreakpointValue } from "@chakra-ui/react";
import { useCurrentPlatform } from "itinerator-api";
import {
  ButtonClickTrackingPlugin,
  enableButtonClickTracking,
} from "@snowplow/browser-plugin-button-click-tracking";
import { GeolocationPlugin } from "@snowplow/browser-plugin-geolocation";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  refreshLinkClickTracking,
} from "@snowplow/browser-plugin-link-click-tracking";
import { TimezonePlugin } from "@snowplow/browser-plugin-timezone";
import {
  enableActivityTracking,
  newTracker,
  trackPageView,
} from "@snowplow/browser-tracker";
import {
  MouseEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useConsent } from "~/providers";

export const useIsMobile = () =>
  !!useBreakpointValue({ base: true, md: false, lg: false });
export const useIsDesktop = () =>
  !!useBreakpointValue({ base: false, md: false, lg: true });
export const useNavbarHeight = () =>
  useBreakpointValue({ base: 120, lg: 155 }, { ssr: false }) || 120;

export const useDraggable = (
  ourRef: MutableRefObject<HTMLDivElement | null>
) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const handleDragStart: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current;
    const startX = e.clientX;
    const startY = e.clientY;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };

  const handleDrag: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current;
    const walkX = (e.clientX - mouseCoords.current.startX) * 1.25;
    const walkY = (e.clientY - mouseCoords.current.startY) * 1.25;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  };
  return {
    onMouseDown: handleDragStart,
    onMouseMove: handleDrag,
    onMouseUp: handleDragEnd,
  };
};

export const useTracking = () => {
  const { consentGiven } = useConsent(); // Access the consent status from the context
  const { data: platform } = useCurrentPlatform();

  useEffect(() => {
    // newTracker can be called multiple time, only first instantiation is taken into account
    if (consentGiven === "yes" && platform?.name) {
      console.log("tracking enabled");
      newTracker("sp1", "sp.itinerator.ca", {
        appId: platform.name,
        plugins: [
          LinkClickTrackingPlugin(),
          ButtonClickTrackingPlugin(),
          TimezonePlugin(),
          GeolocationPlugin(),
        ],
      });

      enableActivityTracking({
        minimumVisitLength: 30,
        heartbeatDelay: 10,
      });

      const filter = (linkElement: any) => {
        return linkElement.href.indexOf(document.domain) > -1 ? false : true;
      };

      trackPageView();
      enableLinkClickTracking({
        pseudoClicks: true,
        options: { filter: filter },
      });
      refreshLinkClickTracking();
      enableButtonClickTracking({
        filter: (element) => {
          return element.id.includes("t-"); // any button we want to track should have a "t-" prefix
        },
      });
    }
  }, [consentGiven, platform?.name]);
};
