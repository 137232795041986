import pluralize from "pluralize";
import {
  ItineratorListingStop,
  ItineratorRegionStop,
  ItineratorNoteStop,
  ItineratorStop,
  ItineratorNotNoteStop,
  ItineratorSimplePlatformExtension,
  ItineratorPlatform,
} from ".";
import lodash from "lodash";
// Type Narrowing NoteNicole
export const isNoteStop = (stop: ItineratorStop) =>
  stop.display_type === "note";

export const isNotNoteStop = (stop: ItineratorStop) =>
  stop.display_type === "step";

export const isTargetStop = (
  stop: ItineratorStop
): stop is ItineratorRegionStop | ItineratorListingStop =>
  stop.target_type !== null;

export const isRegionStop = (
  stop: ItineratorStop
): stop is ItineratorRegionStop => stop.target_type === "Region";

export const isListingStop = (
  stop: ItineratorStop
): stop is ItineratorListingStop => stop.target_type === "Listing";

export const isPlatformExtensionType = <
  T extends ItineratorSimplePlatformExtension["type"]
>(
  type: T
) => {
  return (
    extension: ItineratorSimplePlatformExtension
  ): extension is ItineratorSimplePlatformExtension & { type: T } =>
    extension.type === type;
};

export const findPlatformExtension = <
  T extends ItineratorSimplePlatformExtension["type"]
>(
  extensions: ItineratorSimplePlatformExtension[] = [],
  type: T
) => extensions.find(isPlatformExtensionType(type));

type Terms = ItineratorPlatform["terms"];
type GetTermOptions = {
  plural?: boolean | number;
  capitalize?: boolean;
  inclusive?: boolean;
};

export const getTermFunction =
  (terms: Terms) =>
  (
    name: keyof Terms,
    { plural, capitalize, inclusive }: GetTermOptions = {}
  ) => {
    let term = terms[name];
    if (typeof plural === "boolean") {
      term = pluralize(term, plural ? 2 : 1);
    } else if (typeof plural === "number") {
      term = pluralize(term, plural, inclusive);
    }
    return capitalize ? lodash.startCase(term) : term;
  };
