/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "./http-client";

export class Logout<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Session
   * @name LogoutDelete
   * @summary Logout of itinerator
   * @request DELETE:/logout
   */
  logoutDelete = (params: RequestParams = {}) =>
    this.request<
      {
        status: number;
        logged_in: boolean;
      },
      any
    >({
      path: `/logout`,
      method: "DELETE",
      format: "json",
      ...params,
    });
}
