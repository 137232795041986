/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ItineratorError,
  ItineratorGetListingsByTypeParams,
  ItineratorGetListingsParams,
  ItineratorListing,
  ItineratorListingByType,
  ItineratorListingList,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Listings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Listing
   * @name GetListings
   * @summary List all listings
   * @request GET:/listings
   * @secure
   */
  getListings = (query?: ItineratorGetListingsParams, params: RequestParams = {}) =>
    this.request<ItineratorListingList, ItineratorError>({
      path: `/listings`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Listing
   * @name GetListingBySlug
   * @summary Find a listing by slug
   * @request GET:/listings/{slug}
   * @secure
   */
  getListingBySlug = (slug: string, params: RequestParams = {}) =>
    this.request<ItineratorListing, ItineratorError>({
      path: `/listings/${slug}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Listing
   * @name GetListingsByType
   * @summary List all listings grouped by main_type
   * @request GET:/listings/by_type
   * @secure
   */
  getListingsByType = (query?: ItineratorGetListingsByTypeParams, params: RequestParams = {}) =>
    this.request<ItineratorListingByType, ItineratorError>({
      path: `/listings/by_type`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
}
