/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ItineratorError,
  ItineratorGetPlatformUserTasksParams,
  ItineratorPlatformUserTask,
  ItineratorPlatformUserTaskList,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class PlatformUserTasks<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PlatformUserTask
   * @name GetPlatformUserTasks
   * @summary List all platform user tasks
   * @request GET:/platform_user_tasks
   * @secure
   */
  getPlatformUserTasks = (query?: ItineratorGetPlatformUserTasksParams, params: RequestParams = {}) =>
    this.request<ItineratorPlatformUserTaskList, ItineratorError>({
      path: `/platform_user_tasks`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags PlatformUserTask
   * @name GetPlatformUserTaskBySlug
   * @summary Find a platform user task by slug
   * @request GET:/platform_user_tasks/{slug}
   * @secure
   */
  getPlatformUserTaskBySlug = (slug: string, params: RequestParams = {}) =>
    this.request<ItineratorPlatformUserTask, ItineratorError>({
      path: `/platform_user_tasks/${slug}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
