/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { HttpClient, RequestParams } from "./http-client";

export class LoggedIn<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Session
   * @name LoggedInList
   * @summary Check login status
   * @request GET:/logged_in
   */
  loggedInList = (params: RequestParams = {}) =>
    this.request<
      {
        auth: {
          logged_in: boolean;
          status: number;
          message: string;
        };
        user?: {
          id: number;
          name: string;
          email: string;
        };
      },
      any
    >({
      path: `/logged_in`,
      method: "GET",
      format: "json",
      ...params,
    });
}
