/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ItineratorContent,
  ItineratorContentList,
  ItineratorError,
  ItineratorGetContentsParams,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Contents<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Content
   * @name GetContents
   * @summary List all contents
   * @request GET:/contents
   * @secure
   */
  getContents = (query?: ItineratorGetContentsParams, params: RequestParams = {}) =>
    this.request<ItineratorContentList, ItineratorError>({
      path: `/contents`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Content
   * @name GetContentBySlug
   * @summary Find a content by slug
   * @request GET:/contents/{slug}
   * @secure
   */
  getContentBySlug = (slug: string, params: RequestParams = {}) =>
    this.request<ItineratorContent, ItineratorError>({
      path: `/contents/${slug}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
