import { Box, FlexProps } from "@chakra-ui/react";
import { regionApi } from "itinerator-api";
import { useQuery } from "@tanstack/react-query";
import { useRef } from "react";
import { MapSVG } from "./MapSVG";

type MapGroupProps = {
  showPopup?: boolean;
};

type CommunitiesMapProps = MapGroupProps &
  FlexProps & {
    text?: string;
    onClose?: () => void;
  };
export const CommunitiesMap = ({
  text,
  showPopup,
  onClose,
  ...props
}: CommunitiesMapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { data: regions = [] } = useQuery({
    queryKey: ["regions"],
    queryFn: () => regionApi.getRegions(),
  });

  return (
    <Box position={"relative"} ref={ref} h="100%" display="flex" {...props}>
      <MapSVG showPopup={showPopup} regions={regions} />
    </Box>
  );
};
