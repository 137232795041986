import { Box, BoxProps } from "@chakra-ui/react";
import { ItineratorSimpleRegion } from "itinerator-api";
import { useRef } from "react";
import { COMMUNITIES_ORDERED_SLUGS } from "~/lib/constants";
import { MapDot } from "./MapDot";
import { RoadPath, RoadPathProps } from "./RoadPath";

type MapSVGProps = BoxProps &
  RoadPathProps & {
    regions?: ItineratorSimpleRegion[];
    showPopup?: boolean;
    externalLink?: boolean;
  };

export const MapSVG = ({
  regions,
  showPopup,
  externalLink,
  roadColor,
  textColor,
  ...props
}: MapSVGProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <Box position="relative" ref={ref} flexGrow={1} h="100%" {...props}>
      <svg viewBox="0 0 2044.419 457.996" height="100%" fill="none">
        <RoadPath roadColor={roadColor} textColor={textColor} />
        {COMMUNITIES_ORDERED_SLUGS.map(({ id, dots }) =>
          dots.map((dot) => (
            <MapDot
              key={`${id}-${dot.cx}-${dot.cy}`}
              containerRef={ref}
              {...dot}
              region={regions?.find((region) => region.id === id)}
              showPopup={showPopup}
              externalLink={externalLink}
            />
          ))
        )}
      </svg>
    </Box>
  );
};
