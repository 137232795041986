import { Link, LinkProps } from "@chakra-ui/next-js";
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  usePopoverContext,
  useTheme,
} from "@chakra-ui/react";
import { ItineratorSimpleRegion } from "itinerator-api";
import { IconX } from "@tabler/icons-react";
import { RefObject, useState } from "react";
import { CommunityDot } from "~/lib/constants";
import { MapCommunityCard } from "../Cards";

type Props = CommunityDot & {
  linkProps?: Partial<LinkProps>;
  region?: ItineratorSimpleRegion;
  containerRef: RefObject<HTMLDivElement>;
  showPopup?: boolean;
  onClose?: () => void;
  color?: string;
  externalLink?: boolean;
};

export const MapDot = ({ linkProps, ...props }: Props) => {
  const { containerRef, region, showPopup, link, externalLink } = props;

  const { colors } = useTheme();
  const [color, setColor] = useState(colors.blue["500"]);
  if (!showPopup)
    return (
      <Link
        href={externalLink ? link : `/communities/${region?.slug}`}
        onClick={props.onClose}
        onMouseEnter={() => setColor(colors.yellow[500])}
        onMouseLeave={() => setColor(colors.blue["500"])}
        target={externalLink ? "_blank" : undefined}
      >
        <Dot {...props} color={color} />
      </Link>
    );
  return (
    <Popover boundary={containerRef.current || undefined}>
      <PopoverTrigger>
        <Box
          as="a"
          cursor="pointer"
          onMouseEnter={() => setColor(colors.yellow[500])}
          onMouseLeave={() => setColor(colors.blue["500"])}
        >
          <Dot {...props} color={color} />
        </Box>
      </PopoverTrigger>
      <Portal containerRef={containerRef}>
        <PopoverContent
          width="unset"
          borderColor="gray.800"
          borderWidth={2}
          borderRadius={10}
          overflow="hidden"
        >
          <CloseButton />
          {!!region && <MapCommunityCard region={region} />}
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const Dot = (props: Omit<Props, "linkProps">) => {
  const { cx, cy, color } = props;
  return (
    <>
      <circle
        cx={cx}
        cy={cy}
        r={8}
        fill={color}
        stroke={color}
        strokeWidth="2"
        pointerEvents={"none"}
      >
        <animate
          attributeName="r"
          from="8"
          to="16"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.5"
          to="0"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="r"
          from="8"
          to="24"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="0.25"
          to="0"
          dur="1.5s"
          begin="0s"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={cx} cy={cy} r={8} fill={color} />
    </>
  );
};

export const CloseButton = ({ color }: { color?: string }) => {
  const { onClose } = usePopoverContext();
  return (
    <Box
      cursor="pointer"
      onClick={onClose}
      pos="absolute"
      top={4}
      right={4}
      color="gray.900"
      borderRadius={"full"}
      borderColor="gray.900"
      borderWidth={2}
      bgColor="white"
      zIndex={2}
    >
      <IconX />
    </Box>
  );
};
