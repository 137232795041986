/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ItineratorAlbum, ItineratorAlbumList, ItineratorError, ItineratorGetAlbumsParams } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Albums<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Album
   * @name GetAlbums
   * @summary List all albums
   * @request GET:/albums
   * @secure
   */
  getAlbums = (query?: ItineratorGetAlbumsParams, params: RequestParams = {}) =>
    this.request<ItineratorAlbumList, ItineratorError>({
      path: `/albums`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Album
   * @name GetAlbumBySlug
   * @summary Find a album by slug
   * @request GET:/albums/{slug}
   * @secure
   */
  getAlbumBySlug = (slug: string, params: RequestParams = {}) =>
    this.request<ItineratorAlbum, ItineratorError>({
      path: `/albums/${slug}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
