/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Login<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Session
   * @name LoginCreate
   * @summary Login to itinerator
   * @request POST:/login
   */
  loginCreate = (
    data: {
      user: {
        token: string;
      };
    },
    params: RequestParams = {},
  ) =>
    this.request<
      {
        auth: {
          logged_in: boolean;
          status: number;
          message: string;
        };
        user?: {
          id: number;
          name: string;
          email: string;
        };
      },
      any
    >({
      path: `/login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
