import { Contents } from "./generated/Contents";
import { Events } from "./generated/Events";
import { Forms } from "./generated/Forms";
import { Itineraries } from "./generated/Itineraries";
import { Listings } from "./generated/Listings";
import { Locations } from "./generated/Locations";
import { LoggedIn } from "./generated/LoggedIn";
import { Login } from "./generated/Login";
import { Logout } from "./generated/Logout";
import { Newsletters } from "./generated/Newsletters";
import { PlatformExtensions } from "./generated/PlatformExtensions";
import { Platforms } from "./generated/Platforms";
import { PlatformUserTasks } from "./generated/PlatformUserTasks";
import { Reels } from "./generated/Reels";
import { Regions } from "./generated/Regions";
import { Challenges } from "./generated/Challenges";
import { Rewards } from "./generated/Rewards";
import { Albums } from "./generated/Albums";
import { FormEntries } from "./generated/FormEntries";

export { type RequestParams, ContentType } from "./generated/http-client";
export const regionApi = new Regions();
export const listingApi = new Listings();
export const platformApi = new Platforms();
export const itineraryApi = new Itineraries();
export const eventApi = new Events();
export const platformExtensionApi = new PlatformExtensions();
export const reelApi = new Reels();
export const formApi = new Forms();
export const newsletterApi = new Newsletters();
export const contentApi = new Contents();
export const platfromUserTaskApi = new PlatformUserTasks();
export const locationsApi = new Locations();
export const loginApi = new Login();
export const logoutApi = new Logout();
export const loggedInApi = new LoggedIn();
export const challengesApi = new Challenges();
export const rewardsApi = new Rewards();
export const albumsApi = new Albums();
export const formEntriesApi = new FormEntries();

export * from "./generated/data-contracts";
export * from "./helpers";
export * from "./queries";
export * from "./auth";
