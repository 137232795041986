/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ItineratorError, ItineratorGetReelsParams, ItineratorReel, ItineratorReelList } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Reels<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Reel
   * @name GetReels
   * @summary List all reels
   * @request GET:/reels
   * @secure
   */
  getReels = (query?: ItineratorGetReelsParams, params: RequestParams = {}) =>
    this.request<ItineratorReelList, ItineratorError>({
      path: `/reels`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Reel
   * @name GetReelBySlug
   * @summary Find a reel by slug
   * @request GET:/reels/{slug}
   * @secure
   */
  getReelBySlug = (slug: string, params: RequestParams = {}) =>
    this.request<ItineratorReel, ItineratorError>({
      path: `/reels/${slug}`,
      method: "GET",
      secure: true,
      ...params,
    });
}
